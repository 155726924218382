import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import "./layout.css"

const Container = styled.div`
  height:95vh;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`

const Footer = styled.footer`
text-align:center;
font-size:.8em;
`
const MainContainer = styled.div`
margin: 0 auto;
max-width: 960;
font-size:2.5em;
`
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Container>
        <Header siteTitle={data.site.siteMetadata.title} />
        <MainContainer>
          <main>{children}</main>
        </MainContainer>
        <Footer>
          © {new Date().getFullYear()}
          {` `}
          <a href="https://www.jsdesigns.ca">JSdesigns</a>
        </Footer>
      </Container>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
