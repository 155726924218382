import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="❤️ Your Website" keywords={[`design`, `cloud`, `technology`]} />
    <p><em>Love your website.</em></p>
  </Layout>
)

export default IndexPage
